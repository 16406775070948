<template>
  <ion-page>
    <Header name="Datenschutzerklärung" />
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid style="padding:0">
          <ion-row>
            <ion-col style="padding:0">
              <div class="card">
                <p><a @click.prevent="$cc.showSettings()">Cookie Einstellungen</a></p>
                <p>Wir freuen uns über Ihr Interesse an unserer News-App für Beschäftigte der Uniklinik Köln und deren Tochterunternehmen.&nbsp;Die News-App dient der Bereitstellung von Informationen für die interessierte Öffentlichkeit und Beschäftigte.&nbsp;Für externe Links zu fremden Inhalten können wir dabei trotz sorgfältiger inhaltlicher Kontrolle keine Haftung übernehmen.<br> <br> Der Schutz Ihrer personenbezogenen Daten bei der&nbsp;Nutzung der App&nbsp;ist uns ein wichtiges Anliegen. Ihre Daten werden im Rahmen der gesetzlichen Vorschriften geschützt.</p>
                <p><strong>Name und Anschrift des Verantwortlichen</strong></p>
                <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten der EU sowie sonstiger datenschutzrechtlicher Bestimmungen ist das:</p>
                <p>Universitätsklinikum Köln AöR<br> Kerpener Str. 62<br> 50937 Köln<br> Deutschland<br> Telefon +49 221 478-0<br> E-Mail <a href="mailto:presse@uk-koeln.de" title="Mail schreiben" class="mail">presse@uk-koeln.de</a></p>
                <p><strong>Name und Anschrift des Datenschutzbeauftragten</strong></p>
                <p>Dominik Zier<br> Kerpener Str. 62<br> 50937 Köln<br> Deutschland<br> Telefon +49 221 478-88008<br> E-Mail <a href="mailto:datenschutz@uk-koeln.de" title="Mail schreiben" class="mail">datenschutz@uk-koeln.de</a></p>
                <p> </p>
                <h5>1. Erhebung personenbezogener Daten bei Nutzung der App</h5>
                <p>Erhoben und verwendet werden personenbezogene Daten der Nutzenden grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen App sowie unserer Inhalte und Leistungen erforderlich ist.</p>
                <p>Jede Nutzung unserer App und jeder Abruf einer in der App hinterlegten Datei werden protokolliert.</p>
                <p>Protokolliert werden: Name der abgerufenen Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge. Zusätzlich werden die IP-Adressen der anfragenden Geräte protokolliert. Die Registrierung der Zugriffe erfolgt aus Gründen der Datensicherheit und um die Stabilität und die Betriebssicherheit unseres Systems zu gewährleisten und gegen mögliche Angriffe von außen zu schützen.</p>
                <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die Speicherung der Daten in Logfiles nach spätestens sieben Tagen der Fall.</p>
                <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
                <h5>2. Daten-Berechtigungen in der App</h5>
                <p>Wenn Sie Push-Benachrichtigungen erhalten möchten, müssen Sie uns dies erlauben. Wir fragen bei der ersten Installation (Android) oder beim ersten Verwenden (iOS) der App danach. Benachrichtigungseinstellungen können im Betriebssystem nachträglich an- oder ausgeschaltet werden. Wir verwenden für die Push-Benachrichtigung die Dienste Firebase Cloud Messaging der Firma Google (Android) und Apple Push Notifications (iOS). Dabei generieren Firebase und Apple einen berechneten Schlüssel, der sich aus der Kennung der App und Ihrer Geräte-Kennung zusammensetzt. Dieser Schlüssel wird auf unserer Push-Plattform mit den von Ihnen gewählten Einstellungen hinterlegt, um Ihnen die Inhalte Ihren Wünschen entsprechend zur Verfügung zu stellen. Die Firebase- bzw. Apple-Server können keinerlei Rückschluss auf die Anfragen von Nutzenden ziehen oder sonstige Daten ermitteln, die mit einer Person im Zusammenhang stehen. Firebase bzw. Apple dienen ausschließlich als Übermittler.</p>
                <h5>3. Ihre Rechte</h5>
                <p>Gemäß Art. 7 Abs. 3 DSGVO können Sie Ihre einmal erteilte Einwilligung jederzeit widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen. Nach Maßgabe von Art. 15 DSGVO haben Sie das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten einschließlich eventueller Empfänger und der geplanten Speicherdauer zu erhalten. Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen gemäß Art. 16 DSGVO ein Recht auf Berichtigung zu. Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen sowie Widerspruch gegen die Verarbeitung einlegen (Art. 17, 18 und 21 DSGVO). Zudem besteht ein Beschwerderecht gegenüber der Aufsichtsbehörde:</p>
                <p>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br> Postfach 20 04 44<br> 40102 Düsseldorf<br> 0211/38424-0<br> <a href="mailto:poststelle@ldi.nrw.de" title="Mail schreiben" class="mail">poststelle@ldi.nrw.de</a></p>
                <h5>4. Cookies</h5>
                <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten. Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten der App bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
                <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Nutzen Sie die App erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen. Zum anderen setzen wir Cookies ein, um den Push-Notification-Service zu ermöglichen. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht. Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies&nbsp;gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen&nbsp;nutzen können.</p>
                <h5>5. Google Firebase</h5>
                <p>Die App nutzt Firebase Cloud Messaging, um Nachrichten an Nutzer der App zu senden. Hierfür sendet die App einen zuvor generierte, anonyme Geräte-ID (Token) an Google, damit wir Nutzer der App identifizeren und mit Nachrichteninhalten adressieren können. Informationen rund um die Funktionsweise von Cloud Messaging findest du hier: <a href="https://firebase.google.com/products/cloud-messaging/" target="_blank">https://firebase.google.com/products/cloud-messaging/</a></p>
                <h5>6. Social Media</h5>
                <p><strong>Einsatz von Social-Media-Plug-ins</strong></p>
                <ul>
                  <p> </p>
                  <li>Wir setzen derzeit folgende Social-Media-Plug-ins ein: Twitter.&nbsp;Dieser Betreiber verarbeitet&nbsp;und speichert&nbsp;Daten der Nutzenden.&nbsp;Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.</li>
                  <li>Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bieten wir Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.</li>
                  <li>Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z. B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.</li>
                  <li>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.</li>
                  <li>Adresse&nbsp;des Plug-in-Anbieters und URL mit deren Datenschutzhinweisen:<br> Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA; <a href="https://twitter.com/privacy" target="_blank">https://twitter.com/privacy</a>.</li>
                  <p></p>
                </ul>
                <p>Bitte beachten Sie, dass beim Einsatz der Tools gegebenenfalls eine Übermittlung Ihrer Daten an Empfänger außerhalb des EWR erfolgen kann, in denen kein angemessenes Datenschutzniveau gemäß der DSGVO existiert (z. B. USA).</p>
                <h5>7. Matomo</h5>
                <p>Wir verwenden die Open-Source-Software Matomo zur Analyse und statistischen Auswertung der Nutzung der App gemäß Art. 6 Abs. 1 lit. f DSGVO. Hierzu werden Cookies eingesetzt (siehe Ziffer 5). Die durch den Cookie erzeugten Informationen über die Nutzung werden an unsere Server übertragen und in pseudonymen Nutzungsprofilen zusammengefasst. Die Informationen werden verwendet, um die Nutzung der App auszuwerten und um eine bedarfsgerechte Gestaltung zu ermöglichen. Eine Weitergabe der Informationen an Dritte erfolgt nicht.</p>
                <p>Es wird in keinem Fall die IP-Adresse mit anderen den Nutzer betreffenden Daten in Verbindung gebracht. Die IP-Adressen werden anonymisiert, sodass eine Zuordnung nicht möglich ist (IP-Masking).</p>
                <p>Sie können die Datenerfassung durch Matomo verhindern, indem Sie in den <a @click.prevent="$cc.showSettings()">Cookie Einstellungen</a> die Verwendung von "Analyse-Cookies" deaktivieren.</p>
                <h5>8. Aktualität und Änderung dieser Datenschutzerklärung</h5>
                <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Juni 2022. Durch die Weiterentwicklung unserer App und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonCol, IonGrid, IonRow } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default  {
  name: 'PrivacyPolicy',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow 
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
h1 {
  font-size: 28px;
  line-height: 37.7px;
  color: var(--text-color-blue);
  margin-top: 0;
}
p {
  font-size: 15px;
  line-height: 28px;
  color: var(--text-color-grey);
}

@media (prefers-color-scheme: dark) {
  p {
    color: var(--text-color-lightgrey);
  }
}
body.dark {
  p {
    color: var(--text-color-lightgrey);
  }
}
</style>